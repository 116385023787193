<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content
          class="mobile indexLayoutContent"
          style="padding: 0px"
        >
          <div class="page mlist flex-col justify-start" style="height: auto">
            <div class="mswiper windowheight">
              <top-b
                ref="topb"
                :kind="`list`"
                :topbarhide="topbarhide"
                :tb="tb"
                :w750="w750"
                :topbarOpacity="topbarOpacity"
                :lag="lag"
                :selectlag="1"
                @baoming="baominglrthis"
              ></top-b>

              <div class="swiper sc">
                <div :class="`swiper-wrapper`">
                  <div :class="`swiper-slide ssm0`">
                    <div class="swiper lsc0">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div class="group_detall flex-col">
                            <div
                              class="gdetall flex-col"
                              :style="`min-height:${pageHeight}px`"
                            >
                              <div class="gdetallin">
                                <div class="dcontent">
                                  <template v-if="data.kind == 40">
                                    <div class="d40info">
                                      <div
                                        class="img"
                                        :style="`background-image:url(${
                                          baseUrl + data.imgurl
                                        })`"
                                      ></div>

                                      <div class="d40infolist">
                                        <div class="d40title">
                                          <div class="zmbdiv">
                                            <div
                                              class="zmb zmbf"
                                              v-if="data.state == '0'"
                                            >
                                              敬请期待
                                            </div>
                                            <div
                                              class="zmb"
                                              v-if="data.state == '1'"
                                            >
                                              招募中
                                            </div>
                                            <div
                                              class="zmb zmbs"
                                              v-if="data.state == '2'"
                                            >
                                              报名截止
                                            </div>
                                            <div
                                              class="zmb zmbs"
                                              v-if="data.state == '3'"
                                            >
                                              已结束
                                            </div>

                                            <!-- <div
                                              class="zmb zmbf"
                                              v-if="data.startdate > nowdate"
                                            >
                                              敬请期待
                                            </div>
                                            <div
                                              class="zmb"
                                              v-if="
                                                data.startdate < nowdate &&
                                                data.finaldate > nowdate
                                              "
                                            >
                                              招募中
                                            </div>
                                            <div
                                              class="zmb zmbs"
                                              v-if="data.finaldate < nowdate"
                                            >
                                              已结束
                                            </div> -->
                                          </div>
                                          <span>{{ data.pname }}</span>
                                        </div>

                                        <div class="d40tinfo">
                                          <span
                                            v-if="
                                              data.startdate != '0' &&
                                              data.startdate != ''
                                            "
                                            class="date"
                                            v-html="
                                              data.startdate +
                                              '-' +
                                              data.finaldate
                                            "
                                          ></span>
                                          <span
                                            class="adr"
                                            v-html="data.adr"
                                          ></span>
                                        </div>

                                        <div
                                          class="d40cc"
                                          v-if="data.ccdata.length > 0"
                                        >
                                          <div class="tt">场次</div>
                                          <div class="cclist">
                                            <template
                                              v-for="(
                                                item, index
                                              ) in data.ccdata"
                                            >
                                              <div
                                                :class="`ccitem ${
                                                  item.acount <= item.bcount
                                                    ? 'dis'
                                                    : ''
                                                }  ${
                                                  ccselectindex == index
                                                    ? 'ccselected'
                                                    : ''
                                                }`"
                                                :key="index"
                                                @click="
                                                  () => {
                                                    if (
                                                      item.acount > item.bcount
                                                    ) {
                                                      ccSelect(index, item.id);
                                                    }
                                                  }
                                                "
                                              >
                                                {{ item.title }}
                                                <div
                                                  class="tip"
                                                  v-if="
                                                    item.acount <= item.bcount
                                                  "
                                                >
                                                  已满
                                                </div>
                                              </div>
                                            </template>
                                          </div>
                                        </div>

                                        <div
                                          class="d40price"
                                          v-if="data.ccdata.length > 0"
                                        >
                                          <div class="tt">剩余</div>
                                          <div class="count">
                                            {{
                                              data.ccdata[ccselectindex]
                                                .acount -
                                              data.ccdata[ccselectindex].bcount
                                            }}
                                          </div>
                                        </div>

                                        <div
                                          class="d40price"
                                          v-if="data.ccdata.length > 0"
                                        >
                                          <div class="tt">价格</div>
                                          <div class="price">
                                            <span
                                              v-html="
                                                data.ccdata[ccselectindex].price
                                              "
                                            >
                                            </span
                                            ><span class="dw">元</span>
                                          </div>
                                        </div>

                                        <template
                                          v-if="
                                            data.ccdata.length > 0 &&
                                            data.state == '1' &&
                                            (basedata.loginshow == 'true' ||
                                              basedata.dev)
                                          "
                                        >
                                          <template v-if="mypdata.length == 0">
                                            <template
                                              v-if="
                                                data.ccdata[ccselectindex]
                                                  .acount <=
                                                data.ccdata[ccselectindex]
                                                  .bcount
                                              "
                                            >
                                              <div
                                                class="d40btn"
                                                style="
                                                  margin-left: 0px;
                                                  background-color: #ccc;
                                                  color: #fff;
                                                "
                                              >
                                                <div class="btn">已满</div>
                                              </div>
                                            </template>
                                            <template v-else>
                                              <div
                                                class="d40btn"
                                                style="margin-left: 0px"
                                                @click="
                                                  () => {
                                                    baomingthis(
                                                      data.kind,
                                                      data.id,
                                                      data.ccdata[ccselectindex]
                                                        .id
                                                    );
                                                  }
                                                "
                                              >
                                                <div class="btn">报名</div>
                                              </div>
                                            </template>
                                          </template>
                                          <template v-else>
                                            <div
                                              v-if="mypdata[0].pay == '0'"
                                              class="d40btn"
                                              style="
                                                margin-left: 0px;
                                                background-color: rgba(
                                                  255,
                                                  255,
                                                  255,
                                                  0.5
                                                );
                                                border: 1px solid
                                                  rgba(247, 96, 31, 1);
                                                color: rgba(247, 96, 31, 1);
                                              "
                                              @click="
                                                paythis(mypdata[0].out_trade_no)
                                              "
                                            >
                                              <div class="btn">现在支付</div>
                                            </div>
                                            <div
                                              v-else
                                              class="d40btn"
                                              style="
                                                margin-left: 0px;
                                                background-color: #ccc;
                                                color: #fff;
                                              "
                                            >
                                              <div class="btn">您已报名</div>
                                            </div>
                                          </template>
                                        </template>
                                        <template
                                          v-if="
                                            data.state == '2' &&
                                            (basedata.loginshow == 'true' ||
                                              basedata.dev)
                                          "
                                        >
                                          <div
                                            class="d40btn"
                                            style="
                                              margin-left: 0px;
                                              background-color: #ccc;
                                              color: #fff;
                                            "
                                          >
                                            <div class="btn">报名截止</div>
                                          </div>
                                        </template>

                                        <template
                                          v-if="
                                            data.state == '3' &&
                                            (basedata.loginshow == 'true' ||
                                              basedata.dev)
                                          "
                                        >
                                          <div
                                            class="d40btn"
                                            style="
                                              margin-left: 0px;
                                              background-color: #ccc;
                                              color: #fff;
                                            "
                                          >
                                            <div class="btn">已结束</div>
                                          </div>
                                        </template>
                                      </div>
                                    </div>
                                  </template>

                                  <template v-if="data.kind == 33">
                                    <div class="d40info">
                                      <div
                                        class="img"
                                        :style="`background-image:url(${
                                          baseUrl + data.imgurl
                                        })`"
                                      ></div>

                                      <div class="d40infolist">
                                        <div
                                          class="d40title"
                                          style="text-indent: 0px"
                                        >
                                          {{ data.pname }}
                                        </div>

                                        <div class="d40tinfo">
                                          <span
                                            v-if="
                                              data.startdate != '0' &&
                                              data.startdate != ''
                                            "
                                            class="date"
                                            v-html="
                                              data.startdate +
                                              '-' +
                                              data.finaldate
                                            "
                                          ></span>
                                          <span
                                            class="adr"
                                            v-html="data.adr"
                                          ></span>
                                        </div>

                                        <div
                                          class="d40cc"
                                          v-if="data.ccdata.length > 0"
                                        >
                                          <div class="tt">场次</div>
                                          <div class="cclist">
                                            <template
                                              v-for="(
                                                item, index
                                              ) in data.ccdata"
                                            >
                                              <div
                                                :class="`ccitem ${
                                                  item.acount <= item.bcount
                                                    ? 'dis'
                                                    : ''
                                                }  ${
                                                  ccselectindex == index
                                                    ? 'ccselected'
                                                    : ''
                                                }`"
                                                :key="index"
                                                @click="
                                                  () => {
                                                    if (
                                                      item.acount > item.bcount
                                                    ) {
                                                      ccSelect(index, item.id);
                                                    }
                                                  }
                                                "
                                              >
                                                {{ item.title }}
                                                <div
                                                  class="tip"
                                                  v-if="
                                                    item.acount <= item.bcount
                                                  "
                                                >
                                                  已满
                                                </div>
                                              </div>
                                            </template>
                                          </div>
                                        </div>

                                        <!-- <div class="d40price" v-if="data.ccdata.length>0">
                            <div class="tt">价格</div>
                            <div class="price">
                              <span v-html="data.ccdata[ccselectindex].price">
                              </span
                              ><span class="dw">元</span>
                            </div>
                          </div> -->
                                        <template
                                          v-if="
                                            data.state == '1' &&
                                            (basedata.loginshow == 'true' ||
                                              basedata.dev)
                                          "
                                        >
                                          <div
                                            class="d40btn"
                                            v-if="
                                              data.sdata.filter((item) => {
                                                return (
                                                  item.memberid ==
                                                  memberinfo.member_id
                                                );
                                              }).length == 0
                                            "
                                            style="margin-left: 0px"
                                            @click="
                                              () => {
                                                baomingthis(data.kind, data.id);
                                              }
                                            "
                                          >
                                            <div class="btn">报名</div>
                                          </div>
                                          <div
                                            class="d40btn"
                                            v-else
                                            style="
                                              margin-left: 0px;
                                              background-color: #ccc;
                                              color: #fff;
                                            "
                                          >
                                            <div class="btn">您已报名</div>
                                          </div>
                                        </template>
                                        <template
                                          v-if="
                                            data.state == '2' &&
                                            (basedata.loginshow == 'true' ||
                                              basedata.dev)
                                          "
                                        >
                                          <div
                                            class="d40btn"
                                            style="
                                              margin-left: 0px;
                                              background-color: #ccc;
                                              color: #fff;
                                            "
                                          >
                                            <div class="btn">报名截止</div>
                                          </div>
                                        </template>

                                        <template
                                          v-if="
                                            data.state == '3' &&
                                            (basedata.loginshow == 'true' ||
                                              basedata.dev)
                                          "
                                        >
                                          <div
                                            class="d40btn"
                                            style="
                                              margin-left: 0px;
                                              background-color: #ccc;
                                              color: #fff;
                                            "
                                          >
                                            <div class="btn">已结束</div>
                                          </div>
                                        </template>
                                        
                                      </div>
                                    </div>
                                  </template>

                                  <template v-else>
                                    <div
                                      class="dtitle"
                                      v-html="data.pname"
                                      v-if="data.kind != 40 && data.kind != 33"
                                    ></div>
                                    <div class="dfd">
                                      <span style="margin-right: 20px">
                                        ALL FIELDS{{ data.from }}
                                      </span>
                                      <span v-html="data.addtime"></span>
                                    </div>
                                  </template>

                                  <div
                                    class="content"
                                    v-html="data.content"
                                  ></div>

                                  <!-- <div class="daohang">
                    <span v-if="kind == '00'">
                      FM.看看世界
                    </span>
                    <span v-if="kind == '01'">
                      趣野故事会
                    </span>
                    <span v-if="kind == '02'">
                      趣野视角
                    </span>
                    <span v-if="kind == '10'">
                      KOL日常
                    </span>
                    <span v-if="kind == '11'">
                      合作品牌
                    </span>
                    <span v-if="kind == '20'">
                      好物推荐
                    </span>
                    <span v-if="kind == '21'">
                      购物平台
                    </span>
                    <span v-if="kind == '30'">
                      现场SHOW什么？
                    </span>
                    <span v-if="kind == '31'">
                      新鲜SHOW闻
                    </span>
                    <span v-if="kind == '32'">
                      Ta们在SHOW
                    </span>
                    <span v-if="kind == '33'">
                      想来SHOW一下么？
                    </span>


                    <span v-if="kind == '40'">
                      趣野人集合
                    </span>
                    <span v-if="kind == '41'">
                      精彩回放
                    </span>
                    
                    
                  </div> -->
                                </div>
                              </div>
                              <div class="backimg"></div>
                              <div class="backimgf"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="`swiper-slide ssm0`">
                    <footer-b
                      :kind="`index`"
                      :g8top="g8top"
                      :g8left="g8left"
                      :lag="lag"
                    ></footer-b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchClass,
  fetchCase,
  fetchCaseOne,
  fetchFriend,
  fetchBase,
  fetchAwards,
  addSignup,
  checkMember,
} from "../api/index";
import FooterB from "../components/FooterB.vue";
import TopB from "../components/TopB.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar,
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel, Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    // FooterIndex,
    FooterB,
    TopB,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      mypdata: [],
      memberinfo: {},
      bmshow: 0,
      bmstate: false,
      bmid: "0",

      pagesize: 12,
      pageno: 0,
      total: 40,
      sbindex: "",
      data: {
        //   id: "1",
        //   title: "Rapha是骑行届的「颜值顶流」,&nbsp;没人反对吧？",
        //   des: "2020年后出生的一代人，他们在社交方面与前几代人有着很大的不同…",
        //   from: "ALL fields",
        //   jianjie:
        //     "这里写一段描述内容，这里写一段描述内容，这里写一段描述内容。这里写一段描述内容，这里写一段描述内容，这里写一段描述内容",
        //   photo:
        //     "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng407ddf08f8701eab20f7f48a9b28a6e911834718a6050bb68d55b31ffc2aa6c2",
        //   pname: "ALL fields",
        //   addtime: "2023-08-31 20:00",
        //   url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf616a7b3e8d00d2341756dd81b1606488b19d2f435d5424f927f74320d40896c",
        //   content:`
        //   <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/36429016f3214ad58524568add250f3c_mergeImage.png" /><span class="paragraph_1"
        //   >骑行趋势火热的当下，这已经不止是一门运动也不止是一门通勤用的交通工具了，在追求速度、性能能赶得及上班打卡的同时，颜值也成了大家的「骑有此理」。<br /><br />看看上海武康路、安福路上，复古精致的小布骑行党们的车上挂着可爱的挂件，又或者看看潮流骑行玩家们的&nbsp;tokyobike，在固有的复古色彩车身的基础上，爱车也有几件为&nbsp;OOTD&nbsp;打卡的配饰之物。</span
        // >`,
      },
      pageHeight: 0,
      tbanimate: "down",
      winHeight: 0,
      topbarhide: 0,
      id: "0",
      kind: "",
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper0: null,

      swiper1: null,

      swiper2: null,

      swiper3: null,

      swiper03: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      gtitle: "整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销",
      gdesc:
        "IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。<br />成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。",
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,

      sectionindex: 0,
      sections: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: 68,
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,

      opy1: 0,
      opy2: 0,
      opy3: 0,
      opy4: 0,
      opy5: 0,
      opy6: 0,
      opy7: 0,
      opy8: 0,

      group1h: 0,
      group2h: 0,
      group3h: 0,
      group4h: 0,
      group5h: 0,
      group6h: 0,
      group7h: 0,

      tb: true,
      masktop: 0,
      masksize: 0,
      g8top: 0,
      g8left: 0,
      ccselectindex: 0,

      basedata: {},

      selectkind: 0,
      selectid: 0,
      selectcid: 0,

      initHeight: 0,
    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      // let video0 = document.getElementById("video0");
      // video0.play();
    }, 1000);
    this.alive = true;
    this.handleScroll;
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    let that = this;

    this.memberinfo = global.getMemberinfo();

    let id = this.$route.params.id;

    let kind = this.$route.params.kind;

    let lag = this.$route.params.lag;

    if (id) {
      this.id = id;
    }
    if (kind) {
      this.kind = kind;
    }

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    global.setIndexDclass("");
    global.setIndexscroll(0);
    // that.handleScroll;
    that.initHeight = window.innerHeight;
    that.resizepage();
    // this.$el.addEventListener("scroll", that.handleScroll, true);
    window.addEventListener("resize", that.handleResize, true);

    setTimeout(() => {
      that.initHeight = window.innerHeight;
      that.resizepage();
    }, 500);

    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
    }, 1000);

    this.fetchone();
    this.myswiper();
    this.nowdate = this.getNowDate();
    this.basedata = global.getbaseinfo();
  },
  beforeCreate() {
    let that = this;
  },
  methods: {
    baominglrthis() {
      this.memberinfo = global.getMemberinfo();
      this.baomingthis(this.selectkind, this.selectid, this.selectcid);
    },
    baomingthis(kind, id, cid = 0) {
      const that = this;

      const h = this.$createElement;

      let memberdata = global.getMemberinfo();

      console.log("memberdata:", memberdata);

      let params = { kind: kind, mid: id, cid: cid };

      console.log(params);

      if (memberdata.member_id == "" || memberdata.isEmpty > 0) {
        this.selectkind = kind;
        this.selectid = id;
        this.selectcid = cid;
        that.$refs.topb.openlr("baoming");
      } else {
        this.$confirm({
          title: "确认报名",
          content: "您是否确认报名",
          class: "confirmDiv",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            let out_trade_no = "";

            if (cid != 0) {
              out_trade_no = `CC${kind}${cid}${Date.parse(new Date())}${
                that.memberinfo.member_id
              }`;
            } else {
              out_trade_no = `SH${kind}${cid}${Date.parse(new Date())}${
                that.memberinfo.member_id
              }`;
            }

            // return false;

            params.out_trade_no = out_trade_no;

            addSignup(params).then((res) => {
              console.log("addSignup:", res);
              that.loading = false;

              if (res.results) {
                let message = res.results.message;

                if (message == 0) {
                  that.$refs.topb.openlr();
                } else if (message == 1) {
                  console.log("SIGNUP SUCCESS ....");

                  that.$info({
                    title: "现在支付",
                    content: h("div", {}, [
                      h(
                        "p",
                        `请在30分钟内付款 ${
                          that.data.ccdata[that.ccselectindex].price
                        }元`
                      ),
                    ]),
                    onOk() {
                      that.paythis(out_trade_no);
                      // console.log("that:",that);
                      // that.$router.push(`/payed/${out_trade_no}/`);
                    },
                  });

                  that.fetchone();
                  that.memberinfo = global.getMemberinfo();
                } else if (message == 3) {
                  alert("您已经报过名了！");
                  that.fetchone();
                  that.memberinfo = global.getMemberinfo();
                }
              }
            });
          },
        });
      }
      // console.log("baomingthis:", kind, id, cid);
    },

    paythis(out_trade_no) {
      console.log("paythis");
      this.$router.push(`/payed/${out_trade_no}/`);
    },

    ccSelect(index, id) {
      console.log("ccselect:", index);
      this.ccselectindex = index;
      this.ccselectid = id;

      this.mypdata = this.data.sdata.filter((item) => {
        return (
          item.memberid == this.memberinfo.member_id &&
          item.cid == this.data.ccdata[this.ccselectindex].id
        );
      });
      console.log("mypdata:", this.mypdata);
    },

    getNowDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      month = month > 9 ? month : "0" + month;
      day = day < 10 ? "0" + day : day;
      var today = year + "/" + month + "/" + day;
      return today;
    },

    myswiper0() {
      let that = this;

      setTimeout(() => {
        this.lswiper0 = new Swiper(".lsc0", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          freeMode: true,
          // scrollbar: {
          //   el: ".swiper-scrollbar",
          // },
        });
      }, 10);
    },
    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".sc", {
          duration: 1000,
          direction: "vertical",
          resistanceRatio: 0,
          on: {
            transitionEnd: function () {
              that.mselectIndex = this.activeIndex;
              if (that.mselectIndex > 0) {
                that.tb = false;
              } else {
                that.tb = true;
              }
            },
            init: function (swiper) {
              this.emit("transitionEnd");
            },
          },
        });

        // this.lswiper0 = new Swiper(".lsc0", {
        //   //子swiper
        //   direction: "vertical",
        //   nested: true,
        //   resistanceRatio: 0,
        //   slidesPerView: "auto",
        //   freeMode: true,
        //   // scrollbar: {
        //   //   el: ".swiper-scrollbar",
        //   // },
        // });
        // this.lswiper1 = new Swiper(".lsc1", {
        //   //子swiper
        //   direction: "vertical",
        //   nested: true,
        //   resistanceRatio: 0,
        //   slidesPerView: "auto",
        //   freeMode: true,
        //   // scrollbar: {
        //   //   el: ".swiper-scrollbar",
        //   // },
        // });

        // this.lswiper2 = new Swiper(".lsc2", {
        //   //子swiper
        //   direction: "vertical",
        //   nested: true,
        //   resistanceRatio: 0,
        //   slidesPerView: "auto",
        //   freeMode: true,
        //   // scrollbar: {
        //   //   el: ".swiper-scrollbar",
        //   // },
        // });
      }, 10);
    },

    fetchone(params = { id: this.id }) {
      let that = this;
      this.loading = true;

      fetchBase({}).then((res) => {
        that.loading = false;
        that.basedata = res.results;
        global.setbaseinfo(that.basedata);
        that.basedata = global.getbaseinfo();
      });
      checkMember({}).then((res) => {
        let message = res.results.message;
        if (message == 0) {
          global.clearMemberInfo();
          that.memberdata = global.getMemberinfo();
        }
      });
      fetchCaseOne(params).then((res) => {
        console.log("fetchCaseOne:", res);
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list[0];

          that.data.content = that.data.content.replace(
            /<img/g,
            '<img referrerpolicy="no-referrer"'
          );

          that.mypdata = that.data.sdata.filter((item) => {
            return (
              item.memberid == that.memberinfo.member_id &&
              item.cid == that.data.ccdata[that.ccselectindex].id
            );
          });

          setTimeout(() => {
            $(".content")
              .find("img")
              .each(function () {
                $(this).attr("referrerpolicy", "no-referrer");
              });

            $(".content")
              .find("p")
              .each(function () {
                let fontsize = parseInt(
                  $(this).css("font-size").replace("px", "")
                );
                console.log(fontsize);
                $(this).css({ "font-size": `${(fontsize * 750) / 450}px` });
              });
            // $(".content")
            //   .find("span")
            //   .each(function () {
            //     let fontsize = parseInt(
            //       $(this).css("font-size").replace("px", "")
            //     );
            //     console.log(fontsize);
            //     $(this).css({ "font-size": `${(fontsize * 750) / 450}px` });
            //   });

            $(".content")
              .find("span")
              .each(function () {
                let fontsize = parseInt(
                  $(this).css("font-size").replace("px", "")
                );

                let upfontsize = $(this)
                  .parent("p")
                  .css("font-size")
                  .replace("px", "");
                if (upfontsize >= fontsize) {
                  fontsize = upfontsize;
                  $(this).css({ "font-size": `${fontsize}px` });
                } else {
                  $(this).css({ "font-size": `${(fontsize * 750) / 450}px` });
                }
                console.log(fontsize);
              });

            that.myswiper0();
          }, 100);
        } else {
        }
        console.log("data:", that.data);
      });
    },

    sbselect(index) {
      this.sbindex = index;
    },
    swiper1Prev() {
      this.swiper03.slidePrev(500);
    },
    swiper1Next() {
      this.swiper03.slideNext(500);
    },

    handleScroll(e) {
      // console.log("handleScroll");

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        // console.log("e.target.scrollTop:",e.target.scrollTop);
        global.setIndexscroll(winHeight);

        let group1h = $(".group_1").height();
        let group2h = $(".group_2").height();
        let group3h = $(".group_3").height();
        let group4h = $(".group_4").height();
        let group5h = $(".group_5").height();
        let group6h = $(".group_6").height();
        let group7h = $(".group_7").height();
        this.group1h = group1h;
        this.group2h = group2h;
        this.group3h = group3h;
        this.group4h = group4h;
        this.group5h = group5h;
        this.group6h = group6h;
        this.group7h = group7h;

        this.tb = true;

        // if (
        //   this.winHeight >
        //   this.pageHeight +
        //     this.pageHeight +
        //     this.pageHeight +
        //     group2h +
        //     group4h +
        //     group6h +
        //     2000 +
        //     2000
        // ) {
        //   console.log("down");
        //   this.tbanimate = "up";
        //   // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
        //   $(".topbar").removeClass("downtopbar");
        //   $(".topbar").addClass("uptopbar");
        // }

        // if (winHeight > 120) {
        //   if (this.winHeight > winHeight && this.tbanimate != "down") {
        //     this.tbanimate = "down";
        //     // $(".topbar").stop(true).animate({ top: "0px" }, 100);
        //     $(".topbar").removeClass("uptopbar");
        //     $(".topbar").addClass("downtopbar");
        //   } else if (this.winHeight < winHeight && this.tbanimate != "up") {
        //     this.tbanimate = "up";
        //     // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
        //     $(".topbar").removeClass("downtopbar");
        //     $(".topbar").addClass("uptopbar");
        //   }
        // }

        // this.winHeight = winHeight;
        // if (winHeight > 400) {
        //   this.topbarOpacity = (winHeight - 400) / 200;
        //   if (this.topbarOpacity > 1) {
        //     this.topbarOpacity = 1;
        //   }
        // } else {
        //   this.topbarOpacity = 0;
        // }
      }
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetch(
      params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper.update();
        // }, 100);
      });
    },

    gowebsite(url) {
      window.open(url);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },

    resizeViewport() {
      console.log("resizeViewport m");
      let that = this;
      let bl = that.initHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
          // window.location.href = `#/indexm/${this.lag}`;
        }
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        this.$router.replace(`/mdetall/${this.kind}/${this.id}/${this.lag}`);
        // window.location.reload();
      }
    },
    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = that.initHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${pageHeight}px` });

      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);

      // // if (window.innerWidth > 1920) {
      // //   this.pageWidth = 1920;
      // // }
      // if (window.innerWidth <= 750) {
      //   this.sectionindex = 0;
      //   this.m_group_2_height = pageHeight;
      //   this.w750 = 1;
      // } else {
      //   this.m_group_2_height = 0;
      //   this.w750 = 0;
      //   window.location.href = `#/mdetall/${this.kind}/${this.id}/${this.lag}`;
      // }

      this.resizeViewport();

      $("html").css({ fontSize: this.pageWidth / 192 });

      if (that.w750 == 0) {
      } else {
      }
    },
    changelag(lag) {
      if (lag == "en") {
        this.$router.push("index/en");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push("/index");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },

    goIndex() {
      this.$router.push(`/indexl`);
    },

    goSelect(index) {
      this.$router.push(`/sindex/${index}/${this.lag}`);
    },

    goMindex(id2) {
      let url = "";
      let id = this.id;
      if (id == 0) {
        url = "fengxiang";
      }
      if (id == 1) {
        url = "huoban";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "show";
      }
      if (id == 4) {
        url = "club";
      }

      if (url != "") {
        this.$router.push(`/${url}/${id2}/${this.lag}`);
      }
    },

    blinkshandleHover(index) {
      this.bselectindex = index;
      // console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexlm.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
