var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-layout',{staticClass:"mlayout"},[_c('a-layout',[_c('a-layout-content',{staticClass:"mobile indexLayoutContent",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"page mlist flex-col justify-start",staticStyle:{"height":"auto"}},[_c('div',{staticClass:"mswiper windowheight"},[_c('top-b',{ref:"topb",attrs:{"kind":`list`,"topbarhide":_vm.topbarhide,"tb":_vm.tb,"w750":_vm.w750,"topbarOpacity":_vm.topbarOpacity,"lag":_vm.lag,"selectlag":1},on:{"baoming":_vm.baominglrthis}}),_c('div',{staticClass:"swiper sc"},[_c('div',{class:`swiper-wrapper`},[_c('div',{class:`swiper-slide ssm0`},[_c('div',{staticClass:"swiper lsc0"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"group_detall flex-col"},[_c('div',{staticClass:"gdetall flex-col",style:(`min-height:${_vm.pageHeight}px`)},[_c('div',{staticClass:"gdetallin"},[_c('div',{staticClass:"dcontent"},[(_vm.data.kind == 40)?[_c('div',{staticClass:"d40info"},[_c('div',{staticClass:"img",style:(`background-image:url(${
                                        _vm.baseUrl + _vm.data.imgurl
                                      })`)}),_c('div',{staticClass:"d40infolist"},[_c('div',{staticClass:"d40title"},[_c('div',{staticClass:"zmbdiv"},[(_vm.data.state == '0')?_c('div',{staticClass:"zmb zmbf"},[_vm._v(" 敬请期待 ")]):_vm._e(),(_vm.data.state == '1')?_c('div',{staticClass:"zmb"},[_vm._v(" 招募中 ")]):_vm._e(),(_vm.data.state == '2')?_c('div',{staticClass:"zmb zmbs"},[_vm._v(" 报名截止 ")]):_vm._e(),(_vm.data.state == '3')?_c('div',{staticClass:"zmb zmbs"},[_vm._v(" 已结束 ")]):_vm._e()]),_c('span',[_vm._v(_vm._s(_vm.data.pname))])]),_c('div',{staticClass:"d40tinfo"},[(
                                            _vm.data.startdate != '0' &&
                                            _vm.data.startdate != ''
                                          )?_c('span',{staticClass:"date",domProps:{"innerHTML":_vm._s(
                                            _vm.data.startdate +
                                            '-' +
                                            _vm.data.finaldate
                                          )}}):_vm._e(),_c('span',{staticClass:"adr",domProps:{"innerHTML":_vm._s(_vm.data.adr)}})]),(_vm.data.ccdata.length > 0)?_c('div',{staticClass:"d40cc"},[_c('div',{staticClass:"tt"},[_vm._v("场次")]),_c('div',{staticClass:"cclist"},[_vm._l((_vm.data.ccdata),function(item,index){return [_c('div',{key:index,class:`ccitem ${
                                                item.acount <= item.bcount
                                                  ? 'dis'
                                                  : ''
                                              }  ${
                                                _vm.ccselectindex == index
                                                  ? 'ccselected'
                                                  : ''
                                              }`,on:{"click":() => {
                                                  if (
                                                    item.acount > item.bcount
                                                  ) {
                                                    _vm.ccSelect(index, item.id);
                                                  }
                                                }}},[_vm._v(" "+_vm._s(item.title)+" "),(
                                                  item.acount <= item.bcount
                                                )?_c('div',{staticClass:"tip"},[_vm._v(" 已满 ")]):_vm._e()])]})],2)]):_vm._e(),(_vm.data.ccdata.length > 0)?_c('div',{staticClass:"d40price"},[_c('div',{staticClass:"tt"},[_vm._v("剩余")]),_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.data.ccdata[_vm.ccselectindex] .acount - _vm.data.ccdata[_vm.ccselectindex].bcount)+" ")])]):_vm._e(),(_vm.data.ccdata.length > 0)?_c('div',{staticClass:"d40price"},[_c('div',{staticClass:"tt"},[_vm._v("价格")]),_c('div',{staticClass:"price"},[_c('span',{domProps:{"innerHTML":_vm._s(
                                              _vm.data.ccdata[_vm.ccselectindex].price
                                            )}}),_c('span',{staticClass:"dw"},[_vm._v("元")])])]):_vm._e(),(
                                          _vm.data.ccdata.length > 0 &&
                                          _vm.data.state == '1' &&
                                          (_vm.basedata.loginshow == 'true' ||
                                            _vm.basedata.dev)
                                        )?[(_vm.mypdata.length == 0)?[(
                                              _vm.data.ccdata[_vm.ccselectindex]
                                                .acount <=
                                              _vm.data.ccdata[_vm.ccselectindex]
                                                .bcount
                                            )?[_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px","background-color":"#ccc","color":"#fff"}},[_c('div',{staticClass:"btn"},[_vm._v("已满")])])]:[_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px"},on:{"click":() => {
                                                  _vm.baomingthis(
                                                    _vm.data.kind,
                                                    _vm.data.id,
                                                    _vm.data.ccdata[_vm.ccselectindex]
                                                      .id
                                                  );
                                                }}},[_c('div',{staticClass:"btn"},[_vm._v("报名")])])]]:[(_vm.mypdata[0].pay == '0')?_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px","background-color":"rgba(","border":"1px solid","color":"rgba(247, 96, 31, 1)"},on:{"click":function($event){return _vm.paythis(_vm.mypdata[0].out_trade_no)}}},[_c('div',{staticClass:"btn"},[_vm._v("现在支付")])]):_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px","background-color":"#ccc","color":"#fff"}},[_c('div',{staticClass:"btn"},[_vm._v("您已报名")])])]]:_vm._e(),(
                                          _vm.data.state == '2' &&
                                          (_vm.basedata.loginshow == 'true' ||
                                            _vm.basedata.dev)
                                        )?[_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px","background-color":"#ccc","color":"#fff"}},[_c('div',{staticClass:"btn"},[_vm._v("报名截止")])])]:_vm._e(),(
                                          _vm.data.state == '3' &&
                                          (_vm.basedata.loginshow == 'true' ||
                                            _vm.basedata.dev)
                                        )?[_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px","background-color":"#ccc","color":"#fff"}},[_c('div',{staticClass:"btn"},[_vm._v("已结束")])])]:_vm._e()],2)])]:_vm._e(),(_vm.data.kind == 33)?[_c('div',{staticClass:"d40info"},[_c('div',{staticClass:"img",style:(`background-image:url(${
                                        _vm.baseUrl + _vm.data.imgurl
                                      })`)}),_c('div',{staticClass:"d40infolist"},[_c('div',{staticClass:"d40title",staticStyle:{"text-indent":"0px"}},[_vm._v(" "+_vm._s(_vm.data.pname)+" ")]),_c('div',{staticClass:"d40tinfo"},[(
                                            _vm.data.startdate != '0' &&
                                            _vm.data.startdate != ''
                                          )?_c('span',{staticClass:"date",domProps:{"innerHTML":_vm._s(
                                            _vm.data.startdate +
                                            '-' +
                                            _vm.data.finaldate
                                          )}}):_vm._e(),_c('span',{staticClass:"adr",domProps:{"innerHTML":_vm._s(_vm.data.adr)}})]),(_vm.data.ccdata.length > 0)?_c('div',{staticClass:"d40cc"},[_c('div',{staticClass:"tt"},[_vm._v("场次")]),_c('div',{staticClass:"cclist"},[_vm._l((_vm.data.ccdata),function(item,index){return [_c('div',{key:index,class:`ccitem ${
                                                item.acount <= item.bcount
                                                  ? 'dis'
                                                  : ''
                                              }  ${
                                                _vm.ccselectindex == index
                                                  ? 'ccselected'
                                                  : ''
                                              }`,on:{"click":() => {
                                                  if (
                                                    item.acount > item.bcount
                                                  ) {
                                                    _vm.ccSelect(index, item.id);
                                                  }
                                                }}},[_vm._v(" "+_vm._s(item.title)+" "),(
                                                  item.acount <= item.bcount
                                                )?_c('div',{staticClass:"tip"},[_vm._v(" 已满 ")]):_vm._e()])]})],2)]):_vm._e(),(
                                          _vm.data.state == '1' &&
                                          (_vm.basedata.loginshow == 'true' ||
                                            _vm.basedata.dev)
                                        )?[(
                                            _vm.data.sdata.filter((item) => {
                                              return (
                                                item.memberid ==
                                                _vm.memberinfo.member_id
                                              );
                                            }).length == 0
                                          )?_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px"},on:{"click":() => {
                                              _vm.baomingthis(_vm.data.kind, _vm.data.id);
                                            }}},[_c('div',{staticClass:"btn"},[_vm._v("报名")])]):_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px","background-color":"#ccc","color":"#fff"}},[_c('div',{staticClass:"btn"},[_vm._v("您已报名")])])]:_vm._e(),(
                                          _vm.data.state == '2' &&
                                          (_vm.basedata.loginshow == 'true' ||
                                            _vm.basedata.dev)
                                        )?[_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px","background-color":"#ccc","color":"#fff"}},[_c('div',{staticClass:"btn"},[_vm._v("报名截止")])])]:_vm._e(),(
                                          _vm.data.state == '3' &&
                                          (_vm.basedata.loginshow == 'true' ||
                                            _vm.basedata.dev)
                                        )?[_c('div',{staticClass:"d40btn",staticStyle:{"margin-left":"0px","background-color":"#ccc","color":"#fff"}},[_c('div',{staticClass:"btn"},[_vm._v("已结束")])])]:_vm._e()],2)])]:[(_vm.data.kind != 40 && _vm.data.kind != 33)?_c('div',{staticClass:"dtitle",domProps:{"innerHTML":_vm._s(_vm.data.pname)}}):_vm._e(),_c('div',{staticClass:"dfd"},[_c('span',{staticStyle:{"margin-right":"20px"}},[_vm._v(" ALL FIELDS"+_vm._s(_vm.data.from)+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.addtime)}})])],_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.data.content)}})],2)]),_c('div',{staticClass:"backimg"}),_c('div',{staticClass:"backimgf"})])])])])])]),_c('div',{class:`swiper-slide ssm0`},[_c('footer-b',{attrs:{"kind":`index`,"g8top":_vm.g8top,"g8left":_vm.g8left,"lag":_vm.lag}})],1)])])],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }